import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"


import Layout from "../components/layout"
import Imprint from "../components/imprint"

const ImprintPage = ({ data }) => {
  const { body, frontmatter } = data.imprint.edges[0].node
  const { title } = frontmatter
  return (
    <Layout>
      <Imprint>
        <h1 data-testid="heading">{title}</h1>
        <MDXRenderer>{body}</MDXRenderer>
      </Imprint>
    </Layout>
  )
}

export default ImprintPage

export const pageQuery = graphql`
  {
    imprint: allMdx(filter: {fileAbsolutePath: {regex: "/imprint/"}}) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
