import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "./logo"

const StyledFooter = styled.footer`
  width: 100%;
  height: 6.25rem;
  margin: 0 auto;
  margin-top: 1.75rem;
  padding: 0 2.5rem;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: +1px;
  font-weight: 700;
`

const Footer = () => (
  <StyledFooter>
    <a
      href="https://github.com/xFuture603"
      target="_blank"
      rel="nofollow noopener noreferrer"
      aria-label="External Link"
    >
      <span role="img" aria-label="emoji">👏</span> Me on GitHub
    </a>
    <a
      href="https://www.xfuture-blog.com/"
      target="_blank"
      rel="nofollow noopener noreferrer"
      aria-label="External Link"
    >
    <span role="img" aria-label="emoji">📰</span> My Blog
    </a>
    <Link to="/privacy/"><span role="img" aria-label="emoji">🔒privacy</span></Link>

    <Link to="/imprint/"><span role="img" aria-label="emoji">✉️imprint</span></Link>

    <Link to="/">
    <Logo></Logo>
    </Link>
  </StyledFooter>
)

export default Footer
