import React from "react"
import styled from "styled-components"

const StyledLogo = styled.div`
color: white;
font-size: 0.8rem;
text-transform: uppercase;
letter-spacing: +1px;
font-weight: 700;
position: relative;
`

const Logo = ({ size, color }) => (
  <StyledLogo color={color} size={size}>
    Daniel Uhlmann.
  </StyledLogo>
)

export default Logo
