import React from "react"
import styled from "styled-components"

const StyledSection = styled.section`
  .title {
    margin-bottom: 0;
  }
  .subtitle {
    margin-top: 0;
  }
  .highlighted {
    box-shadow: inset 0 -2.5rem 0 #cdf3e1;
  }
`
const Imprint = ({children}) => {
  return (
    <StyledSection id="imprint">
      {children}
    </StyledSection>
  )
}

export default Imprint
