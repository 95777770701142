import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/xfuture_logo.png"

const Header = ({ siteTitle }) => (
<header style={{ background: `white`, marginBottom: `1.45rem`, }}>
<Link to="/">
    <div className="mb-7 mt-auto flex-1 pt-8">
        <img src={logo} alt="logo" className="w-24 h-24 rounded-full mx-8" />
    </div>
</Link>
</header>
)

export default Header
